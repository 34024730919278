<template>
    <div>
        <base-header base-title="Drive Thru Lab"></base-header>
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <auto-refresh
                                :table-name="tableName"
                                :on-refresh="fetch"
                            ></auto-refresh
                            >&nbsp;&nbsp;
                        </div>
                    </div>
                </div>
                <div class="mt-3 mr-3 d-flex justify-content-end">
                    <quick-filter
                        :changedValueStart.sync="qckTglKunjunganPickedStart"
                        :changedValueEnd.sync="qckTglKunjunganPickedEnd"
                    ></quick-filter>
                    <quick-filter
                        :changedValueStart.sync="qckEndDatePickedStart"
                        :changedValueEnd.sync="qckEndDatePickedEnd"
                        :textBtn="'Quick Filter Batas Hasil Paket'"
                    ></quick-filter>
                </div>
                <div class="kt-portlet__body">
                    <v-server-table
                        id="table_lab"
                        :columns="columns"
                        :options="options"
                        ref="table"
                        name="petugas_table"
                    >
                        <div slot="action" slot-scope="{ row }">
                            <btn-action
                                :rowData="row"
                                :petugasLabVue="petugasLabVue"
                                :swabber="swabber"
                            ></btn-action>
                        </div>
                        <div slot="status_lab" slot-scope="{ row }">
                            <pill-status :tests="row" :isStatus="true">
                            </pill-status>
                        </div>
                        <div slot="paketName" slot-scope="{ row }">
                            <pill-status :tests="row" :isStatus="false">
                            </pill-status>
                        </div>
                        <div v-if="!swabber" slot="filter__statusPasien">
                            <check-drop-down
                                :data="statusPicked"
                                :changedValue.sync="statusPicked"
                            ></check-drop-down>
                        </div>
                        <div slot="filter__tanggalKunjungan">
                            <!-- <date-range-picker
                                :changedValueStart.sync="
                                    tglKunjunganPickedStart
                                "
                                :changedValueEnd.sync="tglKunjunganPickedEnd"
                                id-date-picker="filter__tanggalKunjungan"
                                picker-value
                                place-holder="DD/MM/YYYY - DD/MM/YYYY"
                            ></date-range-picker> -->
                            <div style="position: relative;">
                                <date-range-picker
                                    :changedValueStart.sync="
                                        tglKunjunganPickedStart
                                    "
                                    :changedValueEnd.sync="
                                        tglKunjunganPickedEnd
                                    "
                                    id-date-picker="filter__tanggalKunjungan"
                                    picker-value
                                    place-holder="DD/MM/YYYY - DD/MM/YYYY"
                                    :reset.sync="reset"
                                    :newDate="newDateString"
                                >
                                </date-range-picker>
                                <a
                                    class="clear-date-picker"
                                    v-if="tglKunjunganPickedStart"
                                    @click.prevent="clearDates"
                                    ><i class="fas fa-times"></i
                                ></a>
                            </div>
                        </div>
                        <div slot="filter__endDate">
                            <div style="position: relative;">
                                <date-range-picker
                                    :changedValueStart.sync="endDatePickedStart"
                                    :changedValueEnd.sync="endDatePickedEnd"
                                    id-date-picker="filter__endDate"
                                    picker-value
                                    place-holder="DD/MM/YYYY - DD/MM/YYYY"
                                    :reset.sync="resetEndDate"
                                    :newDate="newDateEndDateString"
                                    :swabber="swabber"
                                >
                                </date-range-picker>
                                <a
                                    class="clear-date-picker"
                                    v-if="endDatePickedStart"
                                    @click.prevent="clearEndDates"
                                    ><i class="fas fa-times"></i
                                ></a>
                            </div>
                        </div>
                        <div slot="filter__tanggalLahir">
                            <vue-date-picker
                                v-model="tglLahirPicked"
                                @change="filterByDob"
                                :name="'tanggalLahirFilter'"
                                :clearable="true"
                                :editable="false"
                                :disabled-date="'notAfterToday'"
                                :format="dateFormat"
                            >
                            </vue-date-picker>
                        </div>
                        <div v-if="!swabber" slot="filter__status_lab">
                            <div class="mr-3  mb-3 float-left">
                                <button
                                    class="btn btn-outline-primary dropdown-toggle"
                                    type="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    title="Pilih Status Pasien Yang Dimiliki Data"
                                    style="color: #000000;border-color: #c7c7c7;"
                                >
                                    Show All Data
                                    <div class="text-ellipsis">:</div>
                                </button>
                                <div
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    <label class="dropdown-item d-block pointer"
                                        ><input
                                            @change="showHistoryData"
                                            type="checkbox"
                                            name="checkbox"
                                            value="true"
                                            class="mt-1"
                                            v-model="showHistory"
                                        />&nbsp; SHOW ALL DATA
                                    </label>
                                </div>
                            </div>
                        </div>
                    </v-server-table>
                </div>
                <form-modal
                    v-if="uploadModal"
                    :show-modal.sync="uploadModal"
                    :modalName="modalName"
                    :isUpload="true"
                    :serverTable="$refs.table"
                ></form-modal>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "../_base/BaseTitle.vue";
import BaseHeader from "../_base/BaseHeader.vue";
import { Event } from "vue-tables-2";
import AutoRefresh from "../_general/AutoRefresh.vue";
import BtnAction from "./components/BtnAction.vue";
import PillStatus from "./components/PillStatus.vue";
import FormModal from "./components/form-modal.vue";
import Multiselect from "vue-multiselect";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const DriveThruRepository = RepositoryFactory.get("driveThru");
import { mapActions, mapState, mapMutations } from "vuex";
import VueDatePicker from "../_general/VueDatePicker.vue";
// import DateRangePicker from "../_general/DateRangePicker.vue";
import CheckDropDown from "./components/CheckDropdown.vue";
import DateRangePicker from "../_general/CustomDateRangePicker.vue";
import QuickFilter from "./components/QuickFilter.vue";

export default {
    name: "PetugasLab",
    components: {
        BaseTitle,
        BaseHeader,
        PillStatus,
        FormModal,
        BtnAction,
        PillStatus,
        AutoRefresh,
        Multiselect,
        VueDatePicker,
        CheckDropDown,
        QuickFilter,
        DateRangePicker
    },
    props: {
        swabber: {
            type: Boolean
        }
    },
    data() {
        var vx = this;
        return {
            modalName: "UploadResult",
            headTitle: this.$route.fullPath,
            dateFormat: "DD-MMM-YYYY",
            isShowModalUpload: false,
            isLoaded: false, // ubah jadi false
            tableName: "petugas_lab",
            tglLahirPicked: null,
            petugasLabVue: vx,
            showHistory: false,
            statusPicked: [],
            tglKunjunganPickedStart: null,
            tglKunjunganPickedEnd: null,
            qckTglKunjunganPickedStart: null,
            qckTglKunjunganPickedEnd: null,
            newDateString: "",
            endDatePickedStart: null,
            endDatePickedEnd: null,
            qckEndDatePickedStart: null,
            qckEndDatePickedEnd: null,
            newDateEndDateString: "",
            resetEndDate: false,
            reset: false,
            isFirstLoad: false,
            columns: [
                "fullName",
                "nik",
                "registrasiId",
                "noRegistrasiHis",
                "tanggalLahir",
                "tanggalKunjungan",
                "paketName",
                "endDate",
                "printOut",
                "statusPasien",
                "status_lab",
                "action"
            ],
            options: {
                groupBy: {
                    prop: "orderId",
                    rowTriggerClass: "table-row table-row__group-trigger"
                },
                texts: {
                    filterBy: "Cari"
                },
                filterable: [
                    "registrasiId",
                    "fullName",
                    "noRegistrasiHis",
                    "nik",
                    "paketName"
                ],
                headings: {
                    registrasiId: "Order ID",
                    noRegistrasiHis: "No. HIS",
                    fullName: "Nama Pasien",
                    tanggalLahir: "Tanggal Lahir Pasien",
                    paketName: "Nama Test",
                    printOut: "Print Hasil",
                    endDate: "Batas Waktu Hasil",
                    statusLab: "Status",
                    action: "Actions",
                    statusPasien: "Status Pasien",
                    tanggalKunjungan: "Tanggal Kunjungan",
                    nik: "NIK"
                },
                dateColumns: ["tanggalLahir", "endDate", "tanggalKunjungan"],
                toMomentFormat: "YYYY-MM-DD",
                customFilters: [
                    "statusPasien",
                    "tanggalLahir",
                    "status_lab",
                    "tanggalKunjungan",
                    "endDate"
                ],
                requestFunction: function(data) {
                    let params = {};
                    params.page = data.page - 1;
                    params.size = data.limit;
                    const statusFlowTypes = vx.statusPicked;
                    if (vx.swabber) {
                        params.hasPatientDatang = true;
                        params.hasSampleLabTaken = false;
                    }
                    if (
                        _.isEmpty(data.query) &&
                        !data.statusPasien &&
                        !data.tanggalLahir &&
                        !data.status_lab &&
                        _.isEmpty(data.tanggalKunjungan) &&
                        _.isEmpty(data.endDate) &&
                        !vx.isFirstLoad
                    ) {
                        if (!vx.swabber) {
                            params.showHistory = false;
                            params.endDateStart = moment()
                                .subtract(2, "days")
                                .format("YYYY-MM-DD 00:00:00");
                            params.endDateEnd = moment().format(
                                "YYYY-MM-DD 23:59:59"
                            );
                        }

                        return DriveThruRepository.getPaketPetugasLab(
                            params,
                            statusFlowTypes
                        ).catch(error => {
                            console.log(error);
                        });
                    } else if (vx.isFirstLoad) {
                        let endDate;
                        if (vx.endDatePicked) {
                            let dates = vx.endDatePicked.split(" - ");
                            let start = dates[0];
                            let end = dates[1];
                            if (start !== "null" && end !== "null") {
                                if (!vx.swabber) {
                                    endDate = {
                                        start: moment(start).format(
                                            "YYYY-MM-DD 00:00:00"
                                        ),
                                        end: moment(end).format(
                                            "YYYY-MM-DD 23:59:59"
                                        )
                                    };
                                }
                            }
                        }

                        if (data.tanggalLahir) {
                            params.tglLahir = data.tanggalLahir;
                        }

                        if (!vx.swabber) {
                            params.showHistory = data.status_lab
                                ? data.status_lab
                                : false;
                        }
                        Object.keys(data.query).forEach(key => {
                            if (key == "bookingId") {
                                params.id = data.query[key];
                            } else if (key == "fullName") {
                                params.nama = data.query[key];
                            } else if (key == "noRegistrasiHis") {
                                params.noRegistrasiHIS = data.query[key];
                            } else if (key == "endDate") {
                                params.endDateStart =
                                    data.query.endDate.start.format(
                                        "YYYY-MM-DD"
                                    ) ||
                                    moment()
                                        .subtract(2, "days")
                                        .format("YYYY-MM-DD 00:00:00");
                                params.endDateEnd =
                                    data.query.endDate.end ||
                                    moment().format("YYYY-MM-DD 23:59:59");
                            } else {
                                params[key] = data.query[key];
                            }
                        });
                        if (!_.isEmpty(data.endDate)) {
                            params.endDateStart = data.endDate.start;
                            params.endDateEnd = data.endDate.end;
                        }
                        if (endDate) {
                            params.endDateStart = endDate.start;
                            params.endDateEnd = endDate.end;
                        }
                        if (!_.isEmpty(data.tanggalKunjungan)) {
                            params.tglKunjunganStart =
                                data.tanggalKunjungan.start;
                            params.tglKunjunganEnd = data.tanggalKunjungan.end;
                        }
                        const statPasien = data.statusPasien
                            ? data.statusPasien
                            : statusFlowTypes;

                        return DriveThruRepository.getPaketPetugasLab(
                            params,
                            statPasien
                        ).catch(error => {
                            console.log(error);
                        });
                    }
                },
                responseAdapter: resp => {
                    return {
                        data: resp.data,
                        count: resp.headers["x-total-count"]
                    };
                },
                rowClassCallback(row) {
                    return row.isNewData ? "highlight" : "";
                },
                filterByColumn: true,
                sortable: [],
                templates: {
                    printOut(h, row) {
                        return row.printOut ? `PRINT` : `ONLINE`;
                    },
                    statusPasien(h, row) {
                        const status = row.statusLast
                            ? row.statusLast.replaceAll("_", " ")
                            : "-";
                        return status;
                    },
                    endDate(h, row) {
                        return row.endDate
                            ? moment(row.endDate).format("DD-MMM-YYYY HH:mm")
                            : "-";
                    },
                    registrasiId(h, row) {
                        return row.orderId;
                    },
                    tanggalLahir(h, row) {
                        return row.tanggalLahir
                            ? moment(row.tanggalLahir).format("DD-MMM-YYYY")
                            : "-";
                    },
                    tanggalKunjungan(h, row) {
                        return row.tanggalKunjungan
                            ? moment(row.tanggalKunjungan).format("DD-MMM-YYYY")
                            : "-";
                    }
                }
            }
        };
    },
    methods: {
        ...mapActions("DriveThruStore", {
            sampelDiterima: "sampelDiterima"
        }),
        ...mapMutations("DriveThruStore", {
            setPaketTestUpload: "SET_PAKET_TEST_UPLOAD",
            setUploadModal: "SET_UPLOAD_MODAL"
        }),
        changeTitle(route) {
            let array = route.split("-");
            return (
                array[array.length - 1][0].toUpperCase() +
                array[array.length - 1].slice(1)
            );
        },
        clearDates() {
            this.tglKunjunganPickedStart = null;
            this.tglKunjunganPickedEnd = null;
            this.qckTglKunjunganPickedStart = null;
            this.qckTglKunjunganPickedEnd = null;
            this.reset = true;
        },
        clearEndDates() {
            this.endDatePickedStart = null;
            this.endDatePickedEnd = null;
            this.qckEndDatePickedStart = null;
            this.qckEndDatePickedEnd = null;
            this.resetEndDate = true;
        },
        changeBookingStatus(val) {
            Swal.fire({
                title: `Apakah Sample Specimen ${val.paketName} Milik Pasien ${
                    val.fullName
                } Sudah Diterima ?`,
                html: `
                    <div style="color: red;"> Seluruh Status Sample Specimen Pada Booking <p style="font-weight: 600;"> ${
                        val.orderId
                    } </p> Akan Berubah Menjadi SAMPLE LAB DIAMBIL </div>
                `,
                // text:
                // "Seluruh Status Sample Specimen Pada Booking Ini Akan Berubah Menjadi SAMPLE LAB DIAMBIL",
                confirmButtonText: "IYA",
                cancelButtonText: "TIDAK",
                showCancelButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                allowOutsideClick: false,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.sampelDiterima({
                        data: val.orderId,
                        table: this.$refs.table
                    });
                }
            });
        },
        showUploadModal(val) {
            this.setPaketTestUpload(val);
            this.setUploadModal(false);
            setTimeout(() => {
                this.setUploadModal(true);
            }, 50);
        },
        fetch: function(resolve) {
            this.$refs.table.getData();
            if (resolve != undefined) {
                resolve();
            }
        },
        markAll() {
            Event.$emit(
                "vue-tables.petugas_table.filter::statusPasien",
                this.statusPicked
            );
        },
        showHistoryData() {
            Event.$emit(
                "vue-tables.petugas_table.filter::status_lab",
                this.showHistory
            );
        },
        filterByDob() {
            Event.$emit(
                "vue-tables.petugas_table.filter::tanggalLahir",
                this.tglLahirPicked
            );
        }
    },
    watch: {
        statusPicked: function(val) {
            Event.$emit("vue-tables.petugas_table.filter::statusPasien", val);
        },
        tglKunjunganPicked: function(val) {
            let dates = val.split(" - ");
            let start = dates[0];
            let end = dates[1];
            if (start == "null" && end == "null") {
                Event.$emit(
                    "vue-tables.petugas_table.filter::tanggalKunjungan",
                    ""
                );
            } else {
                Event.$emit(
                    "vue-tables.petugas_table.filter::tanggalKunjungan",
                    {
                        start: moment(start).format("YYYY-MM-DD"),
                        end: moment(end).format("YYYY-MM-DD")
                    }
                );
            }
        },
        endDatePicked: function(newVal) {
            if (this.isFirstLoad || this.swabber) {
                this.isFirstLoad = true;
                let dates = newVal.split(" - ");
                let start = dates[0];
                let end = dates[1];
                if (start == "null" && end == "null") {
                    Event.$emit("vue-tables.petugas_table.filter::endDate", "");
                } else {
                    Event.$emit("vue-tables.petugas_table.filter::endDate", {
                        start: moment(start).format("YYYY-MM-DD 00:00:00"),
                        end: moment(end).format("YYYY-MM-DD 23:59:59")
                    });
                }
            } else {
                this.isFirstLoad = true;
            }
        },
        qckTglKunjunganPicked: function(val) {
            this.newDateString = val;
            let dates = val.split(" - ");
            let start = dates[0];
            let end = dates[1];
            this.tglKunjunganPickedStart = start;
            this.tglKunjunganPickedEnd = end;
        },
        qckEndDatePicked: function(val) {
            this.newDateEndDateString = val;
            let dates = val.split(" - ");
            let start = dates[0];
            let end = dates[1];
            this.endDatePickedStart = start;
            this.endDatePickedEnd = end;
        }
    },
    computed: {
        ...mapState("DriveThruStore", {
            pcrTest: state => state.pcrTest,
            isLoading: state => state.isLoading,
            uploadModal: state => state.uploadModal
        }),
        // compute tglKunjunganPickedStart and tglKunjunganPickedEnd into one string
        tglKunjunganPicked: {
            get() {
                return `${this.tglKunjunganPickedStart} - ${
                    this.tglKunjunganPickedEnd
                }`;
            },
            set(val) {
                const date = val.split(" - ");
                this.tglKunjunganPickedStart = date[0];
                this.tglKunjunganPickedEnd = date[1];
            }
        },
        qckTglKunjunganPicked: {
            get() {
                return `${this.qckTglKunjunganPickedStart} - ${
                    this.qckTglKunjunganPickedEnd
                }`;
            },
            set(val) {
                const date = val.split(" - ");
                this.qckTglKunjunganPickedStart = date[0];
                this.qckTglKunjunganPickedEnd = date[1];
            }
        },
        endDatePicked: {
            get() {
                return `${this.endDatePickedStart} - ${this.endDatePickedEnd}`;
            },
            set(val) {
                const date = val.split(" - ");
                this.endDatePickedStart = date[0];
                this.endDatePickedEnd = date[1];
            }
        },
        qckEndDatePicked: {
            get() {
                return `${this.qckEndDatePickedStart} - ${
                    this.qckEndDatePickedEnd
                }`;
            },
            set(val) {
                const date = val.split(" - ");
                this.qckEndDatePickedStart = date[0];
                this.qckEndDatePickedEnd = date[1];
            }
        }
    },
    mounted() {
        this.headTitle = this.changeTitle(this.$route.fullPath);
        if (!this.swabber) {
            this.newDateEndDateString = `${moment()
                .subtract(2, "days")
                .format("YYYY-MM-DD 00:00:00")} - ${moment().format(
                "YYYY-MM-DD 23:59:59"
            )}`;
            this.endDatePickedStart = moment()
                .subtract(2, "days")
                .format("YYYY-MM-DD 00:00:00");
            this.endDatePickedEnd = moment().format("YYYY-MM-DD 23:59:59");

            // Event.$emit("vue-tables.petugas_table.filter::endDate", {
            //     start: moment()
            //         .subtract(2, "days")
            //         .format("YYYY-MM-DD 00:00:00"),
            //     end: moment().format("YYYY-MM-DD 23:59:59")
            // });
        } else {
            this.isFirstLoad = true;
        }
        // this.isFirstLoad = true;
    },
    async created() {
        this.setUploadModal(false);
    }
};
</script>

<style></style>
