<template>
    <div
        class="modal fade"
        :id="modalName"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true"
    >
        <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            role="document"
        >
            <div v-if="!isUpload" class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">
                        {{ !check ? "Jumlah Pasien" : "Form Pasien" }}
                    </h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="close()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form v-if="!check" class="plus-minus-form">
                        <button
                            class="btn btn-primary big-btn"
                            @click.prevent="addForm"
                        >
                            <i class="fas fa-plus"></i>
                        </button>
                        <div>
                            <input
                                class="form-count-input"
                                style="width: 50px; height: 50px;"
                                v-model="formCountNumber"
                                type="number"
                                min="1"
                                name="form-count"
                                id=""
                            />
                        </div>
                        <button
                            class="btn btn-primary big-btn"
                            @click.prevent="removeForm"
                        >
                            <i class="fas fa-minus"></i>
                        </button>
                    </form>
                </div>

                <div class="modal-footer">
                    <button
                        v-if="!check"
                        @click="createForm"
                        type="button"
                        class="btn btn-primary"
                    >
                        Create Form
                    </button>
                    <div v-else>
                        <button
                            class="btn btn-primary"
                            @click.prevent="newForms"
                        >
                            New Forms
                        </button>
                        <button
                            class="btn btn-primary"
                            @click.prevent="continueToForm"
                        >
                            Continue To Booking Confirmation
                        </button>
                    </div>
                </div>
            </div>
            <div v-else-if="isUpload" class="modal-content">
                <div class="modal-header">
                    <h5
                        class="modal-title"
                        id="exampleModalScrollableTitle"
                        title="test ID"
                    >
                        Upload Hasil {{ paketTestUpload.paketName }} Milik
                        {{ paketTestUpload.fullName }}
                    </h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="close()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="requiredField">
                        <p
                            v-for="(err, index) in required"
                            :key="index"
                            style="color: red"
                        >
                            {{ err }}
                        </p>
                    </div>
                    <!-- Info Pasien: {{ paketTestUpload.fullName }} Tgl Lahir:
                    {{ paketTestUpload.tanggalLahir }} -->
                    <form
                        class="upload-form"
                        style="margin-bottom: 15px; margin-top: 15px; padding: 0.5rem;display: flex;flex-direction: column;align-items: center;"
                    >
                        <div class="file-upload">
                            <div class="file-select">
                                <div class="file-select-button" id="fileName">
                                    Choose File
                                </div>
                                <div class="file-select-name" id="noFile">
                                    No file chosen...
                                </div>
                                <input
                                    accept="image/jpeg, image/png, image/jpg , application/pdf"
                                    type="file"
                                    name="chooseFile"
                                    id="chooseFile"
                                    @change="setFileResult($event)"
                                />
                            </div>
                        </div>
                        <div
                            style="margin-top: 20px;margin-bottom: 20px;padding: 10px;border-radius: 10px;"
                        >
                            <div>
                                <label class="radio">
                                    <input
                                        type="radio"
                                        class="form-control"
                                        name="jenis_kelamin"
                                        v-model="testResult"
                                        value="POSITIF"
                                    />
                                    <span style="color: black;">POSITIF</span>
                                </label>

                                <label class="radio">
                                    <input
                                        type="radio"
                                        class="form-control"
                                        name="jenis_kelamin"
                                        v-model="testResult"
                                        value="NEGATIF"
                                    />
                                    <span style="color: black;">NEGATIF</span>
                                </label>
                            </div>
                        </div>
                        <div style="margin-bottom: 15px;">
                            <label class="result-label" for="result-url">
                                Link Result :
                            </label>
                            <input
                                class="result-input"
                                v-model="linkFile"
                                type="text"
                                name="result-url"
                                id="url-result"
                                placeholder="https://example.com/result.pdf"
                            />
                        </div>
                        <button
                            style="margin-botton: 15px;"
                            @click.prevent="uploadResult"
                            class="btn btn-primary"
                        >
                            Upload
                        </button>
                    </form>
                </div>
                <div class="modal-footer"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    name: "form-modal",
    data() {
        return {
            formCount: 1,
            check: false,
            testResult: null,
            paket: null,
            fileResult: null,
            fileType: null,
            fileb64: null,
            fileName: null,
            linkFile: null,
            requiredField: false,
            required: [],
            currentIndex: null
        };
    },
    props: {
        isUpload: {
            type: Boolean,
            default: false
        },
        modalName: { type: String, default: "driveThruModal" },
        serverTable: { type: Object }
    },
    filters: {
        getPrimaryName(value) {
            let name = "";
            name = `${value.patientLab.namaDepan} ${
                value.patientLab.namaBelakang
                    ? value.patientLab.namaBelakang
                    : ""
            }`;
            return name;
        }
    },
    computed: {
        ...mapState("DriveThruStore", {
            isModalVisible: state => state.isModalVisible,
            paketTestUpload: state => state.paketTestUpload //paketTestUpload.id == testPemeriksaanId
        }),
        formCountNumber: {
            get() {
                return this.formCount;
            },
            set(val) {
                this.formCount = +val;
            }
        }
    },
    methods: {
        ...mapMutations("DriveThruStore", {
            setModalVisible: "SET_MODAL_VISIBLE",
            setFormCount: "SET_FORM_COUNT",
            setForm: "SET_FORM",
            setIsFormSave: "SET_FORM_SAVE",
            setUploadModal: "SET_UPLOAD_MODAL"
        }),
        ...mapActions("DriveThruStore", {
            postResult: "postResult"
        }),
        uploadResult() {
            this.required = [];
            this.requiredField = false;

            if (!this.linkFile && !this.fileb64) {
                this.requiredField = true;
                this.required.push("Result Harus Di upload / Link");
            }

            if (this.required.length == 0) {
                let htmlLink = `<div class="form-check"> `;

                if (this.fileb64) {
                    let anchor = `
                    <p style="color:black;"> Hasil: ${this.testResult} </p>
                    File/Link Result : <a href="data:${this.fileType};base64,${
                        this.fileb64
                    }" download="${this.fileName}" target="_blank">${
                        this.fileName
                    }</a>`;
                    htmlLink += anchor;
                } else {
                    if (this.testResult) {
                        htmlLink += ` <p style="color:black;"> Hasil: ${
                            this.testResult
                        } `;
                    }
                    htmlLink += `</p> File/Link Result : <a href="${
                        this.linkFile
                    }" target="_blank">${this.linkFile}</a>`;
                }
                htmlLink += `</div>`;
                swal.fire({
                    title: `Apakah Hasil Test ${
                        this.paketTestUpload.paketName
                    } untuk Pasien ${
                        this.paketTestUpload.fullName
                    }  Sudah Sesuai?`,
                    html: htmlLink,
                    confirmButtonText: "Iya",
                    showCancelButton: true,
                    cancelButtonText: "Tidak",
                    reverseButtons: true,
                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {
                        this.postResult({
                            data: [
                                {
                                    file: this.fileb64,
                                    fileName: this.fileName,
                                    hasil: this.testResult,
                                    linkFile: this.linkFile,
                                    testPaketId: this.paketTestUpload.paketId,
                                    testPemeriksaanId: this.paketTestUpload
                                        .pemeriksaanId
                                }
                            ],
                            orderId: this.paketTestUpload.orderId,
                            table: this.serverTable
                        });
                        this.close();
                    }
                });
            }
        },
        setFileResult(e, index) {
            var filename = $("#chooseFile").val();
            if (/^\s*$/.test(filename)) {
                $(".file-upload").removeClass("active");
                $("#noFile").text("No file chosen...");
            } else {
                $(".file-upload").addClass("active");
                $("#noFile").text(filename.replace("C:\\fakepath\\", ""));
            }
            const file = e.target.files[0];
            this.fileToB64(index, file);
        },
        close() {
            $("#" + this.modalName).modal("hide");
            this.setModalVisible(false);
            this.setUploadModal(false);
        },
        newForms() {
            localStorage.removeItem("bulkForm");
            this.check = this.checkBulkForm();
        },
        continueToForm() {
            this.setModalVisible(false);
            $("#" + this.modalName).modal("hide");
            setTimeout(() => {
                this.setForm(false);
                this.setIsFormSave(true);
            }, 50);
        },
        createForm() {
            this.setModalVisible(false);
            $("#" + this.modalName).modal("hide");
            setTimeout(() => {
                this.setForm(true);
                this.setFormCount(+this.formCount);
            }, 50);
        },
        addForm() {
            this.formCount++;
        },
        removeForm() {
            if (this.formCount > 1) {
                this.formCount--;
            }
        },
        checkBulkForm() {
            if (localStorage.getItem("bulkForm")) {
                return true;
            }
            return false;
        },
        fileToB64(index, file) {
            var reader = new FileReader();
            this.fileType = file.type;
            this.fileName = file.name;
            reader.readAsDataURL(file);
            reader.onload = e => {
                let fileb64;
                fileb64 = e.target.result;
                fileb64 = fileb64.replace(`data:${this.fileType};base64,`, "");
                this.fileb64 = fileb64;
            };
            reader.onerror = function(error) {
                console.log("Error: ", error);
            };
        }
    },
    mounted() {
        $("#" + this.modalName).modal();
        this.check = this.checkBulkForm();
        if (this.check) {
            document.getElementsByClassName(
                "modal-header"
            )[0].style.borderBottom = "0px solid white";
            document.getElementsByClassName("modal-footer")[0].style.borderTop =
                "0px solid white";
        }
    }
};
</script>
