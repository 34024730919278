<template>
    <div>
        <div v-if="!isStatus">
            <div v-if="tests">
                <div>
                    <label
                        style="text-overflow: ellipsis;width: 100px;white-space: nowrap; overflow: hidden;"
                        :class="
                            checkPastDate(tests.endDate)
                                ? `btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning active`
                                : `btn btn-elevate btn-pill btn-sm mr-2 btn-outline-danger active`
                        "
                    >
                        {{ tests.paketName }}
                    </label>
                    <label
                        v-if="tests.endDate && !tests.resultLabId"
                        style="text-overflow: ellipsis;width: 100px;white-space: nowrap; overflow: hidden;"
                        :class="
                            checkPastDate(tests.endDate)
                                ? `btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning active`
                                : `btn btn-elevate btn-pill btn-sm mr-2 btn-outline-danger active`
                        "
                    >
                        {{ tests.endDate | formatDate(nowTime) }}
                    </label>
                </div>
            </div>
            <div v-else-if="!tests">
                <label
                    :class="
                        printBoolean
                            ? 'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-danger active'
                            : 'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-primary active'
                    "
                >
                    {{ printBoolean ? "PRINT" : "ONLINE" }}
                </label>
            </div>
        </div>
        <div v-else-if="isStatus">
            <div v-if="!tests.hasSampleLabTaken">
                <label
                    :class="
                        'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-secondary active'
                    "
                >
                    {{ "SAMPLE BELUM DI AMBIL" }}
                </label>
            </div>
            <div v-else-if="tests.hasSampleLabTaken && !tests.resultLabId">
                <label
                    :class="
                        'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-info active'
                    "
                >
                    MENUNUGGU UPLOAD HASIL
                </label>
            </div>
            <div v-else-if="tests.resultLabId">
                <label
                    :class="
                        'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-success active'
                    "
                >
                    Result Telah Di Upload
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tests: {
            type: Object
        },
        printBoolean: {
            type: Boolean
        },
        isStatus: {
            type: Boolean
        },
        isDone: {
            type: Boolean
        },
        waitingForUpload: {
            type: Boolean
        },
        isUploaded: {
            type: Boolean
        }
    },
    methods: {
        checkFlowSample() {
            let flag = false;
            this.tests.statusFlow.forEach(ele => {
                if (ele.status == "SAMPLE_LAB_DIAMBIL") {
                    flag = true;
                }
            });
            return flag;
        },
        checkPastDate(date) {
            return moment(date).isAfter(moment());
        },
        checkFlowDikirim() {
            let flag = false;
            this.tests.statusFlow.forEach(ele => {
                if (ele.status == "SAMPLE_LAB_DIKIRIM") {
                    flag = true;
                }
            });
            return flag;
        },
        checkPaket() {
            let flag = false;
            if (this.tests.paketPemeriksaans.length == 0) {
                flag = true;
            }
            return flag;
        }
    },
    data() {
        return {
            nowTime: new Date()
        };
    },
    mounted() {
        setInterval(() => {
            this.nowTime = new Date();
        }, 1000);
    },
    filters: {
        formatDate(date, now) {
            if (date) {
                let Timenow = moment(now);
                let end = moment(date);
                let duration = moment.duration(end.diff(Timenow));
                let hari = duration.days();
                let jam = duration.hours();
                let menit = duration.minutes();
                let detik = duration.seconds();
                let flagMinus = false;

                if (detik <= 0) {
                    flagMinus = true;
                }
                hari = Math.abs(hari);
                jam = Math.abs(jam);
                menit = Math.abs(menit);
                detik = Math.abs(detik);

                jam < 10 ? (jam = "0" + jam) : jam;
                menit < 10 ? (menit = "0" + menit) : menit;
                detik < 10 ? (detik = "0" + detik) : detik;

                return ` ${flagMinus ? "-" : ""}${
                    hari ? hari : ""
                } ${jam}:${menit}:${detik}`;
            }
        }
    }
};
</script>

<style></style>
