var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isStatus)?_c('div',[(_vm.tests)?_c('div',[_c('div',[_c('label',{class:_vm.checkPastDate(_vm.tests.endDate)
                            ? `btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning active`
                            : `btn btn-elevate btn-pill btn-sm mr-2 btn-outline-danger active`,staticStyle:{"text-overflow":"ellipsis","width":"100px","white-space":"nowrap","overflow":"hidden"}},[_vm._v(" "+_vm._s(_vm.tests.paketName)+" ")]),(_vm.tests.endDate && !_vm.tests.resultLabId)?_c('label',{class:_vm.checkPastDate(_vm.tests.endDate)
                            ? `btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning active`
                            : `btn btn-elevate btn-pill btn-sm mr-2 btn-outline-danger active`,staticStyle:{"text-overflow":"ellipsis","width":"100px","white-space":"nowrap","overflow":"hidden"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.tests.endDate,_vm.nowTime))+" ")]):_vm._e()])]):(!_vm.tests)?_c('div',[_c('label',{class:_vm.printBoolean
                        ? 'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-danger active'
                        : 'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-primary active'},[_vm._v(" "+_vm._s(_vm.printBoolean ? "PRINT" : "ONLINE")+" ")])]):_vm._e()]):(_vm.isStatus)?_c('div',[(!_vm.tests.hasSampleLabTaken)?_c('div',[_c('label',{class:'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-secondary active'},[_vm._v(" "+_vm._s("SAMPLE BELUM DI AMBIL")+" ")])]):(_vm.tests.hasSampleLabTaken && !_vm.tests.resultLabId)?_c('div',[_c('label',{class:'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-info active'},[_vm._v(" MENUNUGGU UPLOAD HASIL ")])]):(_vm.tests.resultLabId)?_c('div',[_c('label',{class:'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-success active'},[_vm._v(" Result Telah Di Upload ")])]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }